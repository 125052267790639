import api from '../../lib/ApiBase';


export default {

  /**
   * Update User Password
   *
   * @param {Object} params
   * @param {string} params.current_password - Current User Password
   * @param {string} params.new_password - New Password
   *
   * @returns {AxiosPromise<any>}
   */
  updatePassword(params) {

    return api.post('/api/account/update-password', params);
  },

  /**
   * Update User Profile
   * @param params
   * @returns {AxiosPromise<any>}
   */
  updateProfile(params) {
    return api.post('/api/account/update-profile', params);
  },

  /**
   * Updated settings
   * @param params
   * @returns {AxiosPromise<any>}
   */
  updateDashboardSettings(params) {
    return api.post('/api/account/update-settings/dashboard', params);
  },

  /**
   * Delete Account
   * @param params
   * @returns {AxiosPromise<any>}
   */
  deleteAccount(params) {
    return api.post('/api/account/delete-account', params);
  },

  /**
   * Update last user geolocation
   * @param params
   * @returns {AxiosPromise<any>}
   */
  updateLastGeolocation(params) {
    return api.post('/api/account/last-geo-location', params);
  },

  /**
   * Upload Avatar
   * @param params
   * @returns {AxiosPromise<any>}
   */
  uploadAvatar(params) {

    return api.post('/api/account/upload-avatar', params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  /**
   * Delete Avatar
   * @param params
   * @returns {AxiosPromise<any>}
   */
  deleteAvatar(params) {
    return api.post('/api/account/delete-avatar', params);
  },
}
