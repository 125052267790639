import Vue from 'vue'

import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

export default ({Vue}) => {

  var firebaseConfig = {
    appId: process.env.FIRESTORE_APPID,
    apiKey: process.env.FIRESTORE_APIKEY,
    projectId: process.env.FIRESTORE_PROJECTID,
    authDomain: process.env.FIRESTORE_AUTHDOMAIN,
    measurementId: process.env.FIRESTORE_MEASUREMENTID,
    storageBucket: process.env.FIRESTORE_STORAGEBUCKET,
    messagingSenderId: process.env.FIRESTORE_MESSAGINGSENDERID
  }

  Vue.prototype.$firestore = firebase.initializeApp(firebaseConfig).firestore()

}
