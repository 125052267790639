const state = {
  customSteps: {
    deals: [{
        target: '.nav-item .q-item#deals',
        content: `Deals!`
      },
      {
        target: '.q-page-container .q-table__title',
        content: 'See All Deals!'
      },
    ],
    app: [{
      target: '.nav-item#dashboard',
      content: `<strong>Dashboard</strong> Let's see latest news.`,
      params: {
        placement: 'right',
        enableScrolling: false
      }
    },
    {
      target: '.q-page-container .q-table__container .q-table__title',
      content: 'Service Tickets!',
      params: {
        placement: 'right',
        enableScrolling: false
      }
    },
    {
      target: '.q-page-container .q-table__container:nth-child(2) .q-table__title',
      content: 'Your recent Contacts',
      params: {
        placement: 'right',
        enableScrolling: false
      }
    }]
  },
}

const mutations = {

  customSteps(state, value) {
  debugger;

    state.customSteps = value;
  },

}

const actions = {

  setSteps({commit, state}, value) {
    commit('customSteps', value);
  },

}
const getters = {

  getSteps: (state, name) => {
    return state.customSteps;
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
