import api from './AccountApi';

import store from '../../store';
import {processErrorResponse} from "src/lib/ApiBase";

/**
 * Account Service
 */
export default {

  TIME_FORMAT: {
    TIME_24: 'nGrOy7Bm4lbXbRjMZ52K',
    TIME_12: '7WMEy85k4arVozal1xm9'
  },


  /**
   *
   * @param {Object} params
   * @param {string} params.current_password - Current User Password
   * @param {string} params.new_password - New Password
   *
   * @returns {Promise<unknown>}
   */
  updatePassword(params) {
    return new Promise((resolve, reject) => {

      api.updatePassword(params).then(response => {

        resolve(response.data);

      }).catch(error => {

        reject(processErrorResponse(error.response));
      });

    });
  },

  /**
   * Update User Profile
   * @param params
   * @returns {Promise<unknown>}
   */
  updateProfile(params) {
    return new Promise((resolve, reject) => {

      api.updateProfile(params).then(response => {

        resolve(response.data);

        store.dispatch('auth/update', response.data.data);

      }).catch(error => {

        reject(processErrorResponse(error.response));
      });

    });
  },

  deleteAccount(params) {
    return new Promise((resolve, reject) => {

      api.deleteAccount(params).then(response => {

        resolve(response.data);

      }).catch(error => {
        reject(processErrorResponse(error.response));
      });

    });
  },

  /**
   * Update user last geolocation
   * @param params
   * @returns {Promise<unknown>}
   */
  updateLastGeolocation(params) {
    return new Promise((resolve, reject) => {

      api.updateLastGeolocation(params).then(response => {

        resolve(response.data);

      }).catch(error => {
        reject(processErrorResponse(error.response));
      });

    });
  },

  /**
   * Update User Profile
   * @param params
   * @returns {Promise<unknown>}
   */
  uploadAvatar(params) {
    return new Promise((resolve, reject) => {

      let formData = new FormData();
      formData.append('avatar', params.avatar);

      api.uploadAvatar(formData).then(response => {

        resolve(response.data);

      }).catch(error => {

        reject(processErrorResponse(error.response));
      });

    });
  },

  /**
   * Delete Avatar
   * @param params
   * @returns {Promise<unknown>}
   */
  deleteAvatar(params) {
    return new Promise((resolve, reject) => {

      api.deleteAvatar(params).then(response => {

        resolve(response.data);

      }).catch(error => {

        reject(processErrorResponse(error.response));
      });

    });
  },

  updateDashboardSettings(params) {

    return new Promise((resolve, reject) => {

      api.updateDashboardSettings(params).then(response => {

        resolve(response.data);

      }).catch(error => {

        reject(processErrorResponse(error.response));
      });

    });

  },
}
