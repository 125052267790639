<script>

export default {

  methods: {

    /**
     * Check if user has access to any given permission
     * @param permissions
     * @returns {boolean|*}
     */
    $canAny(permissions) {
      if(permissions == null){
        return  true;
      }
      try {

        if (!Array.isArray(permissions)) {
          permissions = [permissions];
        }

        permissions = permissions.map(perm => perm.toLowerCase());

        return this.$store.getters['auth/user'].permissions.map(perm => perm.toLowerCase()).some(v => permissions.includes(v));

      } catch (e) {
        return false;
      }
    },

    /**
     * Check if user has access to permission
     * @param permissionName
     * @returns {boolean|*}
     */
    $can(permissionName) {

      if (permissionName == null || permissionName === '') {
        return true;
      }

      return this.$store.getters['auth/user'].permissions.map(perm => perm.toLowerCase()).includes(permissionName.toLowerCase());
    },
  },
};
</script>
