const state = {
  drawer_note: false,
  search_query_note: null,
}

const mutations = {

  drawer_note(state, enabled) {
    state.drawer_note = enabled;
  },

  search_query_note(state, value) {
    state.search_query_note = value;
  },

}

const actions = {

  setDrawerNote({commit, state}, enabled) {
    commit('drawer_note', enabled);
  },
  setSearchQueryNote({commit, state}, value) {
    commit('search_query_note', value);
  },

}
const getters = {

  drawer_note: (state) => {
    return state.drawer_note;
  },

  search_query_note: (state) => {
    return state.search_query_note;
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
