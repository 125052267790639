const state = {
  split_view: false,
  custom_view: null,
  query_builder: {
    rules: null,
    filters: null
  },

  right_drawer: false,

  ready: false,

  update_steps: [],

  currentConversation: null,
  ongoingConversations: [],
}

const mutations = {

  right_drawer(state, enabled) {
    state.right_drawer = enabled;
  },

  split_view(state, enabled) {
    state.split_view = enabled;
  },
  custom_view(state, custom_view) {
    state.custom_view = custom_view;
  },
  query_builder_filters(state, filters) {
    state.query_builder.filters = filters;
  },
  query_builder_rules(state, rules) {
    state.query_builder.rules = rules;
  },

  ready(state, ready) {
    state.ready = ready;
  },

  update_steps(state, steps) {
    state.update_steps = steps;
  },

  setCurrentConversation(state, conversation) {
    state.currentConversation = conversation;
  },

  updateOngoingConversations(state, conversations) {
    state.ongoingConversations = conversations;
  },

}

const actions = {

  right_drawer({commit, state}, enabled) {
    commit('right_drawer', enabled);
  },

  split_view({commit, state}, enabled) {
    commit('split_view', enabled);
  },
  custom_view({commit, state}, view) {
    commit('custom_view', view);
  },

  query_builder_filters({commit, state}, filters) {
    commit('query_builder_filters', filters);
  },
  query_builder_rules({commit, state}, rules) {
    commit('query_builder_rules', rules);
  },

  ready({commit, state}, ready) {
    commit('ready', ready);
  },

  update_steps({commit, state}, steps) {
    commit('update_steps', steps);
  },

  updateOngoingConversations({commit, state}, conversations) {
    commit('updateOngoingConversations', conversations);
  },

  setCurrentConversation({commit, state}, conversation) {
    commit('setCurrentConversation', conversation);
  },

  /**
   * Push new conversation to ongoing conversations
   * @param commit
   * @param state
   * @param conversation
   */
  pushConversationToOngoing({commit, state}, conversation) {
    // find if conversation already exists by id
    const index = state.ongoingConversations.findIndex(c => c.id === conversation.id);
    if (index === -1) {
      state.ongoingConversations.unshift(conversation);
    }
  },

  updateOngoingConversation({commit, state}, conversation) {
    // find if conversation already exists by id
    const index = state.ongoingConversations.findIndex(c => c.id === conversation.id);
    if (index !== -1) {

      let conversations = [...state.ongoingConversations];
      conversations[index] = Object.assign({}, conversation);
      commit('updateOngoingConversations', conversations);
    }
  },

  /**
   * Remove conversation from ongoing conversations
   * @param commit
   * @param state
   * @param conversation
   */
  removeConversationFromOngoing({commit, state}, conversation) {
    // find and remove conversation by id
    const index = state.ongoingConversations.findIndex(c => c.id === conversation.id);

    if (index !== -1) {
      let conversations = [...state.ongoingConversations];
      conversations.splice(index, 1);
      commit('updateOngoingConversations', conversations);
    }
  }

}
const getters = {

  right_drawer: (state) => {
    return state.right_drawer;
  },

  split_view: (state) => {
    return state.split_view;
  },
  custom_view: (state) => {
    return state.custom_view;
  },
  query_builder_filters: (state) => {
    return state.query_builder.filters;
  },
  query_builder_rules: (state) => {
    return state.query_builder.rules;
  },

  ready: (state) => {
    return state.ready;
  },

  update_steps(state) {
    return state.steps;
  },

  getOngoingConversations(state) {
    return state.ongoingConversations;
  },
  getCurrentConversation(state) {
    return state.currentConversation;
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
