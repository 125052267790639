/**
 * Get Error from Laravel and convert to Vee-Error
 */
export default {
  install(Vue, options) {
    Vue.prototype.$apiValidatorResponse = function (errorResponse, silent = false, notifyValidationErrors = false) {

      if (!this.hasOwnProperty('$validator')) {
        return;
      }

      this.$validator.errors.clear();

      if (!errorResponse.hasOwnProperty('errors')) {
        return;
      }

      let errorFields = Object.keys(errorResponse.errors);

      /*  // insert laravel errors
        for (let i = 0; i < errorFields.length; i++) {

          let field = errorFields[i];

          let errorString = errorResponse.errors[field].join(', ');
          this.$validator.errors.add({ field: field, msg: errorString });

        }*/

      for (let i = 0; i < errorFields.length; i++) {
        let field = errorFields[i];

        let errorString = errorResponse.errors[field].join(', ');
        this.$validator.errors.add({field: field, msg: errorString});

        if (notifyValidationErrors) {
          this.$q.notify({
            type: 'negative',
            message: errorString
          });
        }
      }

      if (!silent && errorResponse.hasOwnProperty('message') && this.hasOwnProperty('$validator')) {

        this.$q.notify({
          type: 'negative',
          message: errorResponse.message
        });
      }


    };

  },
};
