const state = {
  query: null,
  category: null,
  result: [],
}

const mutations = {

  query(state, query) {
    state.query = query;
  },

  category(state, category) {
    state.category = category;
  },

  result(state, result) {
    state.result = result;
  },
}

const actions = {

  query({commit, state}, query) {
    commit('query', query);
  },

  category({commit, state}, category) {
    commit('category', category);
  },

  result({commit, state}, result) {
    commit('result', result);
  },

}
const getters = {
  query: (state) => {
    return state.query;
  },
  category: (state) => {
    return state.category;
  },

  result: (state) => {
    return state.result;
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
