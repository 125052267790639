const state = {
  drawer: false,
  search_query: null,
}

const mutations = {

  drawer(state, enabled) {
    state.drawer = enabled;
  },

  search_query(state, value) {
    state.search_query = value;
  },

}

const actions = {

  setDrawer({commit, state}, enabled) {
    commit('drawer', enabled);
  },
  setSearchQuery({commit, state}, value) {
    commit('search_query', value);
  },

}
const getters = {

  drawer: (state) => {
    return state.drawer;
  },

  search_query: (state) => {
    return state.search_query;
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
