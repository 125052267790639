import VeeValidate, {Validator} from "vee-validate";
import ApiValidator from "src/lib/ApiValidator";
import Permissions from "src/mixins/Permissions";
import PhoneNumber from 'awesome-phonenumber';

import {i18n} from "boot/i18n";

export default ({Vue}) => {
  Vue.use(VeeValidate);
  Vue.use(ApiValidator);
  Vue.mixin(Permissions);
}

Validator.prototype._handleFieldNotFound = function _handleFieldNotFound(name, scope) {
  // overwritten validation handler file
  // Skip error creation for non existing fields with validation. In application we have multiple dynamic forms.
};

VeeValidate.Validator.extend('strong_password', {
  getMessage: field => i18n.t('strong_password'),
  validate: value => {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    return strongRegex.test(value);
  }
});

VeeValidate.Validator.extend('equalsText', {
  getMessage: field => i18n.t('invalid_text'),
  validate: (value, [text]) => {

    return value == text
  }
});


VeeValidate.Validator.extend('phone', {
  getMessage: field => i18n.t('invalid_phone_number', {field: field}),
  validate: value => {
    return new Promise(resolve => {
      let phone = new PhoneNumber(value);
      resolve({valid: phone.isValid()})
    })
  }
});

// Extend the Validator with a custom rule for array email validation
Validator.extend('emails', {
  getMessage: field => i18n.t('invalid_email_array', { field: field }),
  validate: value => {
    return new Promise(resolve => {
      // Check if value is an array
      if (!Array.isArray(value)) {
        return resolve({ valid: false });
      }

      // Check if every element in the array is a valid email
      const allValid = value.every(email => emailValidator.validate(email));
      resolve({ valid: allValid });
    });
  }
});

// Validat if all elements in array are emails
Validator.extend('arrayEmails', {
  getMessage: field => i18n.t('invalid_email_array', { field: field }),
  validate: value => {
    return new Promise(resolve => {

      if (!Array.isArray(value)) {
        return resolve({ valid: false });
      }

      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      const allValid = value.every(email => emailRegex.test(email));
      resolve({ valid: allValid });
    });
  }
});
