import Vue from 'vue'
import VueRouter from 'vue-router'

import {Dialog, Notify} from 'quasar';
import {i18n} from "boot/i18n";
import routes from './routes';
import {can} from "src/filters/Common";
import AuthService from "src/services/auth/AuthService";
import {getAuthToken, getRefreshToken} from "src/lib/ApiBase";




Vue.use(VueRouter);

let isRouteChangeBlocked = false;

window.onbeforeunload = () => (isRouteChangeBlocked ? true : null);

export function blockRouteChange(set) {

  if (set !== undefined) {
    isRouteChangeBlocked = !!set;
    return isRouteChangeBlocked;
  }
  return isRouteChangeBlocked;
}

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  if (blockRouteChange()) {

    Dialog.create({
      //title: i18n.t('confirm'),
      message: i18n.t('unsaved_changes'),
      cancel: true,
      persistent: true
    }).onOk(() => {
      blockRouteChange(false);
      return originalPush.call(this, location);
    });
    return;
  }
  return originalPush.call(this, location);
};

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default function ({store}) {
  const Router = new VueRouter({
    scrollBehavior: () => ({x: 0, y: 0}),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE,

  })


  Router.beforeEach(async (to, from, next) => {


    if (blockRouteChange()) {

      Dialog.create({
        //title: i18n.t('confirm'),
        message: i18n.t('unsaved_changes'),
        cancel: true,
        persistent: true
      }).onOk(() => {
        blockRouteChange(false);
      });
      return;
    }

    let user = store.getters['auth/user'];
    let isLogged = store.getters['auth/isLogged'];

    if (to.path === '/') {
      return next({path: '/login'});
    }

    /* user is logged and requires for no auth pages*/
    if (to.meta.requiresAuth === false) {
      if (isLogged) {
        return next({path: '/app'});
      }
    }

    if (to.matched.some(m => m.meta.requiresAuth)) {

      /* Verify if user is logged */
      if (!isLogged) { // user is not logged. Redirect to login
        return next({path: '/login'});
      }

      // if store don't have user data or dictionary data  get them from api
      if (!user) {
        await AuthService.refreshProfile();
        await AuthService.loadDictionaries();
      }

      /* Verify user permission */
      if (!!to.meta.permissions && !can(to.meta.permissions)) {
        Notify.create({
          type: 'negative',
          message: "No access to permission: " + to.meta.permissions
        });
        return next({path: '/app'});
      }
    }


    next()
  });

  return Router
}
