import AccountService from "src/services/account/AccountService";

const state = {

  user: null,

  isLogged: false,
  dict: null,
  auth: {
    access_token: null,
  },

}

const mutations = {

  login(state, payload) {

    state.user = payload.user;


    state.auth = {
      access_token: payload.access_token,
    };

    state.isLogged = true;
  },

  dictionaries(state, dictionaries) {
    state.dict = dictionaries;
  },

  logout(state) {

    state.user = null;

    state.dict = null;
    state.auth = {
      access_token: null,
    };
    state.isLogged = false;
  },

  update(state, user) {
    state.user = user;


  }

}

const actions = {

  update({commit}, user) {
    commit('update', user);
  },

  login({commit, state}, payload) {
    commit('login', payload);
  },

  logout({commit, state}) {
    commit('logout');
  },

  dictionaries({commit}, dictionaries) {
    commit('dictionaries', dictionaries);
  }

}

const getters = {

  isLogged: (state) => {
    return state.isLogged;
  },

  user: (state) => {
    return state.user;
  },

  auth: (state) => {
    return state.auth;
  },

  is24TimeFormat: (state) => {
    return AccountService.TIME_FORMAT.TIME_24 === state.user.time_format_id;
  },

  dict: (state) => {
    return state.dict;
  },



}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
