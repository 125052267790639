import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import auth from './store-auth';

import search from './store-search';
import general from './store-general';
import kb from  './store-kb';
import note from  './store-note';
import steps from './store-steps';

// import example from './module-example'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    search,
    general,
    kb,
    note,
    steps

  },
  // enable strict mode (adds overhead!)
  // for dev mode only
  strict: process.env.DEV,

  plugins: [createPersistedState({
    storage: window.localStorage,
    paths: ['auth.isLogged','auth.auth',]
  })]
})
