import store from "src/store";

export const getDescendantProp = (obj, desc) => {
  var arr = desc.split(".");
  while (arr.length && (obj = obj[arr.shift()])) ;
  return obj;
};

export const dictionary = (value, namespace, prop_name = 'value', display = 'label') => {

  try {

    const list = getDescendantProp(store.getters['auth/dict'], namespace);

    return list.find(element => element[prop_name] === value)[display];

  } catch (e) {
    return value;
  }

}

export default {
  getDescendantProp,
  dictionary
}
