
import VueClipboard from "vue-clipboard2";

import VueOffline from 'vue-offline';

import VueGeolocation from 'vue-browser-geolocation';

import VueTheMask from 'vue-the-mask'

import money from 'v-money'

import VueApexCharts from 'vue-apexcharts'

import VueTour from 'vue-tour';
import SimpleVueRegexDirective from "simple-vue-regex-directive";

export default ({Vue}) => {

  VueClipboard.config.autoSetContainer = true;
  Vue.use(VueClipboard);
  Vue.use(VueGeolocation);
  Vue.use(VueTheMask);
  Vue.use(VueTour);
  Vue.use(VueOffline, {
    mixin: false
  });
  Vue.use(money, {precision: 2})

  Vue.component('apexchart', VueApexCharts)

  Vue.directive('regex', SimpleVueRegexDirective({
    // Arbitrary key-value pairs for input's style. See "Locally" for the default.
    color: 'red'
  }));
}


