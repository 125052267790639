import api from './AuthApi';

import store from '../../store';
import {getAuthToken, getRefreshToken, processErrorResponse} from "src/lib/ApiBase";
import Keycloak from "keycloak-js";

let keycloakInstance = new Keycloak({
  url: process.env.KEYCLOAK_URL,
  realm: process.env.KEYCLOAK_REALM,
  clientId: process.env.KEYCLOAK_CLIENT_ID
});

/**
 * Authorization Service
 */
export default {


  /**
   * Login User
   *
   * @param params
   * @param {Object} params - Params
   * @param {string} params.email - Email
   * @param {string} params.password - Password
   *
   * @returns {Promise<unknown>}
   */
  login(params) {

    return new Promise((resolve, reject) => {

      api.login(params).then(response => {

        api._auth(response.data.access_token);

        store.dispatch('auth/login', response.data);

        this.loadDictionaries().then(() => {
          resolve(response.data);
        });

      }).catch(error => {

        reject(processErrorResponse(error.response));
      });

    });

  },

  ssoLogin(params) {

    return new Promise(async (resolve, reject) => {

      api._auth(params.access_token);
      api._refreshToken(params.refresh_token);


      await api.profile().then(async response => {

        await store.dispatch('auth/login', {
          user: response.data.data,
          access_token: params.access_token,
        });

        await this.loadDictionaries().then(() => {
          resolve(response.data);
        })
      }).catch(error => {
        reject(processErrorResponse(error.response));
      });

    });

  },


  /**
   * Login User
   *
   * @param params
   * @param {Object} params - Params
   * @param {string} params.email - Email
   *
   * @returns {Promise<unknown>}
   */
  impersonate(params) {

    return new Promise((resolve, reject) => {

      api.impersonate(params).then(response => {

        api._auth(response.data.access_token);

        store.dispatch('auth/login', response.data);

        this.loadDictionaries().then(() => {
          resolve(response.data);
        });

      }).catch(error => {

        reject(processErrorResponse(error.response));
      });

    });

  },


  refreshProfile() {

    return new Promise((resolve, reject) => {

      api.profile().then(response => {

        store.dispatch('auth/update', response.data.data);

        resolve(response.data);

      }).catch(error => {

        reject(error);
      });

    });
  },


  loadDictionaries() {
    return new Promise((resolve, reject) => {
      api.dictionaries().then(response => {

        resolve(response.data);

        store.dispatch('auth/dictionaries', response.data.data);

      }).catch(error => {
        reject(processErrorResponse(error.response));
      })
    });
  },

  /**
   * Logout user
   *
   * @returns {Promise<unknown>}
   */
  logout() {
    return new Promise(async (resolve, reject) => {

      if (process.env.KEYCLOAK_ENABLED) {

        await keycloakInstance.init({
          checkLoginIframe: false,
          token: getAuthToken(),
          refreshToken: getRefreshToken(),
        }).catch(error => {

          api._auth('');
          api._refreshToken('');
          store.dispatch('auth/logout');

          reject(error);
        });

        keycloakInstance.logout({
          redirectUri: process.env.FRONT_URL + '/#/login?logout=true'
        })

        api._auth('');
        api._refreshToken('');

        await store.dispatch('auth/logout');

      } else {

        await api.logout().then(response => {

          api._auth('');
          api._refreshToken('');

          store.dispatch('auth/logout');
          resolve(response);

        }).catch(error => {
          reject(error);
        })

      }

    });
  },


  /**
   * Reset password for an user.
   *
   * @param {Object} params - Auth Params
   * @param {string} params.email - Email
   * @param {string} params.token - Token from the forgot password email
   * @param {string} params.password - New Password
   *
   * @returns {Promise<unknown>}
   */
  resetPassword(params) {
    return new Promise((resolve, reject) => {

      api.resetPassword(params).then(response => {

        resolve(response.data);

      }).catch(error => {

        reject(processErrorResponse(error.response));
      });

    });
  },

  /**
   * Send forgot password email
   *
   * @param {Object} params - Params
   * @param {string} params.email - User email
   *
   * @returns {Promise<unknown>}
   */
  forgotPassword(params) {
    return new Promise((resolve, reject) => {

      api.forgotPassword(params).then(response => {

        resolve(response.data);

      }).catch(error => {

        reject(processErrorResponse(error.response));
      });

    });
  },


}
