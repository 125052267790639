import api, {setAuthToken, setRefreshToken} from '../../lib/ApiBase';


export default {

  /**
   * Auth Token
   * @param token
   * @private
   */
  _auth(token) {
    setAuthToken(token);
  },

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  },
  getAuthToken(){
    return localStorage.getItem('access_token');
  },

  /**
   * Set Refresh Token
   * @param token
   * @private
   */
  _refreshToken(token) {
    setRefreshToken(token)
  },

  /**
   * Login user
   * @param {Object} params - Params
   * @param {string} params.email - Email
   * @param {string} params.password - Password
   * @returns {AxiosPromise<any>}
   */
  login(params) {
    return api.post('/api/authentication/login', params);
  },

  /**
   * SSO Login
   * @param params
   * @param params.access_token
   * @param params.expires_in
   * @param params.id_token
   * @param params.refresh_token
   * @param params.refresh_expires_in
   * @param params.session_state
   * @returns {AxiosPromise<any>}
   */
  ssoLogin(params){
    return api.post('/api/sso/sso-authorization', params);
  },

  /**
   * Impersonate
   * @param params
   * @returns {AxiosPromise<any>}
   */
  impersonate(params) {
    return api.post('/api/authentication/impersonate', params);
  },

  /**
   * Logout current user.
   * Invalidate user token
   * @param params
   * @returns {AxiosPromise<any>}
   */
  logout(params) {
    return api.post('/api/authentication/logout', params);
  },



  /**
   * Send forgot password email
   * @param {Object} params - Params
   * @param {string} params.email - User email
   * @param {string} params.email - User email
   *
   * @returns {AxiosPromise<any>}
   */
  forgotPassword(params) {
    return api.post('/api/authentication/forgot', params);
  },

  /**
   * Reset password for an user.
   *
   * @param {Object} params - Auth Params
   * @param {string} params.email - Email
   * @param {string} params.token - Token from the forgot password email
   * @param {string} params.password - New Password
   *
   * @returns {AxiosPromise<any>}
   */
  resetPassword(params) {

    return api.post('/api/authentication/reset-password', params);
  },

  /**
   * Get logged user profile
   *
   * @returns {AxiosPromise<any>}
   */
  profile() {
    return api.get('/api/account/profile');
  },

  /**
   * Load dictionaries
   * @returns {AxiosPromise<any>}
   */
  dictionaries() {
    return api.get('/api/dictionaries/load');
  },
}
