import axios from 'axios'
import {Notify} from "quasar";

import AuthService from "src/services/auth/AuthService";
import Keycloak from "keycloak-js";

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
const instance = axios.create({
  baseURL: process.env.API_URL
});

let keycloakInstance = new Keycloak({
  url: process.env.KEYCLOAK_URL,
  realm: process.env.KEYCLOAK_REALM,
  clientId: process.env.KEYCLOAK_CLIENT_ID
});

instance.interceptors.response.use((response) => {
  // Return a successful response back to the calling service
  return response;
}, async (error) => {

  const {config, response} = error;
  const MAX_RETRY = 3;
  const retryDelay = 1000;

  config.retryCount = config.retryCount || 0;

  if (response && response.status === 401) {

    if (process.env.KEYCLOAK_ENABLED) {
      await keycloakInstance.init({
        checkLoginIframe: false,
        token: getAuthToken(),
        refreshToken: getRefreshToken(),
      }).then((authenticated) => {

        if (authenticated) {
          setAuthToken(keycloakInstance.token);
          setRefreshToken(keycloakInstance.refreshToken);

          config.headers.Authorization = `Bearer ${keycloakInstance.token}`;

        } else {
          AuthService.logout().then(res => {
            window.location.replace('/');
          });
        }

      })
        .catch((error) => {
        AuthService.logout().then(res => {
          window.location.replace('/');
        });
      });

      if (config && config.retryCount < MAX_RETRY) {
        config.retryCount += 1;

        return new Promise((resolve) => {
          setTimeout(() => resolve(instance(config)), retryDelay);
        });
      }
    }else{
      AuthService.logout().then(res => {
        window.location.replace('/');
      });
    }
  }

  return Promise.reject(error);

});

export const setAuthToken = (token) => {
  instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  setTimeout(() => localStorage.setItem('auth_token', token));
}
export const setRefreshToken = (token) => {
  setTimeout(() => localStorage.setItem('refresh_token', token));
}

export const getAuthToken = () => {
  return localStorage.getItem('auth_token');
}

export const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
}

/**
 * Process Error Response
 * @param response
 * @returns {*}
 */
export const processErrorResponse = (response) => {

  if(response.status == 401) {
    return
  }

  try {
    if (response.data.message) {

      Notify.create({
        type: response.data.data.message_type || 'negative',
        message: response.data.message,
      });
    }
  } catch (e) {

    Notify.create({
      type: 'negative',
      message: response.data.message,
    });
  }

  return !response ? {message: 'No response..', errors: []} :
    (response.data ? {
      errors: response.data.errors || [],
      message: response.data.message || response.data.exception,
      data: response.data.data
    } : {message: response.statusText, errors: []})
}

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

setAuthToken(localStorage.getItem('auth_token'));


export default instance;
